var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"postcode"},[_c('b-container',[_c('b-form',{on:{"submit":_vm.handleNextPage}},[_c('b-row',{staticClass:"flex-column align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('svg',{attrs:{"width":"41","height":"53","viewBox":"0 0 41 53","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M23.3128 52.9476L40.9711 35.2535L40.9711 30.6234L36.3454 30.6234L24.2699 42.7226L24.2699 3.3703L21 0.0922861L17.7298 3.3703L17.7298 42.7226L5.65462 30.6234L1.02852 30.6234L0.0715318 32.9364L1.02852 35.2535L18.6868 52.9476L23.3128 52.9476Z","fill":"#1C1829"}})]),_c('span',{staticClass:"ml-md-5 ml-4 postcode__text"},[_vm._v(" Start onze unieke woningscan! ")])]),_c('b-col',{staticClass:"mt-md-0 d-flex justify-content-center align-items-baseline mt-3 form_div",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"row no-gutters mr-2"},[_c('div',{staticClass:"postal_code"},[_c('b-form-input',{attrs:{"placeholder":"Postcode","state":_vm.postdata.zip ? (_vm.zipValidation ? !_vm.setAddress : false) : null,"required":""},on:{"input":function($event){return _vm.handleFieldInput('zip')}},model:{value:(_vm.postdata.zip),callback:function ($$v) {_vm.$set(_vm.postdata, "zip", $$v)},expression:"postdata.zip"}}),((!_vm.zipValidation && _vm.postdata.zip) || _vm.setAddress)?_c('b-form-invalid-feedback',{staticClass:"my-1"},[_vm._v(" postcode is niet correct ")]):_vm._e()],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"house_number"},[_c('b-form-input',{attrs:{"placeholder":"Huisnr","state":_vm.postdata.house_number
                    ? _vm.houseNumberValidation
                      ? !_vm.setHouseNumber
                      : false
                    : null,"required":""},on:{"input":function($event){return _vm.handleFieldInput('house_number')}},model:{value:(_vm.postdata.house_number),callback:function ($$v) {_vm.$set(_vm.postdata, "house_number", $$v)},expression:"postdata.house_number"}}),(
                  (!_vm.houseNumberValidation && _vm.postdata.house_number) ||
                  _vm.setHouseNumber
                )?_c('b-form-invalid-feedback',{staticClass:"my-1"},[_vm._v(" Huisnr is niet correct ")]):_vm._e()],1)]),_c('b-button',{staticClass:"mt-3 mx-3 w-max px-4",attrs:{"type":"submit"}},[_vm._v(" Start woningscan ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }