<template>
  <div class="postcode">
    <b-container>
      <b-form @submit="handleNextPage">
        <b-row class="flex-column align-items-center">
          <div class="d-flex align-items-center">
            <!-- <img
              src="@/assets/imgs/arrow-right.png"
              class="img-fluid"
              alt="arrow"
            /> -->
            <svg
              width="41"
              height="53"
              viewBox="0 0 41 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.3128 52.9476L40.9711 35.2535L40.9711 30.6234L36.3454 30.6234L24.2699 42.7226L24.2699 3.3703L21 0.0922861L17.7298 3.3703L17.7298 42.7226L5.65462 30.6234L1.02852 30.6234L0.0715318 32.9364L1.02852 35.2535L18.6868 52.9476L23.3128 52.9476Z"
                fill="#1C1829"
              />
            </svg>

            <span class="ml-md-5 ml-4 postcode__text">
              Start onze unieke woningscan!
            </span>
          </div>
          <b-col
            cols="12"
            md="12"
            class="mt-md-0 d-flex justify-content-center align-items-baseline mt-3 form_div"
          >
            <div class="row no-gutters mr-2">
              <div class="postal_code">
                <b-form-input
                  v-model="postdata.zip"
                  @input="handleFieldInput('zip')"
                  placeholder="Postcode"
                  :state="
                    postdata.zip ? (zipValidation ? !setAddress : false) : null
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback
                  class="my-1"
                  v-if="(!zipValidation && postdata.zip) || setAddress"
                >
                  postcode is niet correct
                </b-form-invalid-feedback>
                <!-- <b-form-input
                  v-model="postdata.zip"
                  @input="handleFieldInput('zip')"
                  placeholder="Postcode"
                  :state="
                    postdata.zip ? (setAddress ? !zipValidation : null) : null
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback v-if="!zipValidation && !setAddress">
                  Voer een geldige postcode in
                </b-form-invalid-feedback> -->
              </div>
            </div>
            <div class="row no-gutters">
              <div class="house_number">
                <b-form-input
                  v-model="postdata.house_number"
                  @input="handleFieldInput('house_number')"
                  placeholder="Huisnr"
                  :state="
                    postdata.house_number
                      ? houseNumberValidation
                        ? !setHouseNumber
                        : false
                      : null
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback
                  class="my-1"
                  v-if="
                    (!houseNumberValidation && postdata.house_number) ||
                    setHouseNumber
                  "
                >
                  Huisnr is niet correct
                </b-form-invalid-feedback>
                <!-- <b-form-input
                  v-model="postdata.house_number"
                  @input="handleFieldInput('house_number')"
                  placeholder="Huisnr."
                  class="ml-2"
                  :state="postdata.house_number ? houseNumberValidation : null"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!houseNumberValidation">
                  Voer een geldige huisnr in
                </b-form-invalid-feedback> -->
              </div>
            </div>

            <!-- <div class="my-3" v-if="setAddress">
              <b-alert show dismissible class="alert-danger">
                Combinatie komt niet overeen!
              </b-alert>
            </div> -->
            <b-button class="mt-3 mx-3 w-max px-4" type="submit">
              Start woningscan
            </b-button>

            <!-- <div class="d-flex">
            <b-form-input v-model="text" placeholder="Postcode"></b-form-input>
            <b-form-input
              v-model="text"
              placeholder="Huisnr."
              class="ml-2"
            ></b-form-input>
          </div> -->
          </b-col>
        </b-row>
      </b-form>
      <b-modal size="lg" hide-footer hide-header v-model="dialog">
        <div v-if="first" class="d-flex flex-column align-items-center">
          <h1 class="text-center modal-text">Even geduld a.u.b.</h1>
          <b-spinner
            class="d-flex align-items-center mt-5"
            label="Loading..."
            style="width: 5rem; height: 5rem"
          ></b-spinner>
        </div>
        <div v-else-if="second">
          <h1 class="text-center modal-text">
            Gefeliciteerd, wij zijn <br />
            beschikbaar in
            <span class="text-green">{{ address.settlement }}</span
            >!
          </h1>
          <h1 class="text-center modal-text mt-4">We sturen je nu door…</h1>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PostCodeForm",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      housenmbrRegex: /^\d+\s*([- ]?\s*[a-zA-Z]+)?$/,
      setAddress: false,
      setHouseNumber: false,
      dialog: false,
      first: false,
      second: false,
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },

  methods: {
    // handleFieldInput(fieldName) {
    //   console.log("phone number changed", fieldName);
    //   if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
    //     console.log("phone number changed2", fieldName);
    //     this.$store.commit("setError", {
    //       ...this.getErrorResponses,
    //       [fieldName]: true,
    //     });
    //   }
    // },
    handleFieldInput(fieldName) {
      console.log("Field changed:", fieldName);

      if (fieldName === "zip") {
        this.zipValidation;
      } else if (fieldName === "house_number") {
        this.houseNumberValidation;
      }

      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    // handleNextPage(e) {
    //   e.preventDefault();

    //   this.loader = true;
    //   this.dialog = true;
    //   this.first = true;

    //   if (this.isValidForm) {
    //     this.getStreetsByZip({
    //       zip: this.postdata.zip,
    //       onSuccess: () => {
    //         this.loader = false;
    //         this.dialog = true;
    //         setTimeout(() => {
    //           this.first = false;
    //           this.second = true;
    //         }, 2000);
    //         setTimeout(() => {
    //           this.$emit("nextPage", "post");
    //           this.dialog = false;
    //         }, 4000);
    //       },
    //       onError: () => {
    //         this.loader = false;
    //         setTimeout(() => {
    //           this.dialog = false;
    //           this.first = false;
    //         }, 2000);
    //         this.setAddress = true;
    //         setTimeout(() => {
    //           this.setAddress = true;
    //         }, 5000);
    //       },
    //     });
    //   }
    // },
    handleNextPage(e) {
      e.preventDefault();

      // this.loader = true;
      // this.dialog = true;
      // this.first = true;

      if (this.isValidForm) {
        if (!this.zipRegex.test(this.postdata.zip)) {
          this.handleValidationError("zip");
          return;
        }
        if (!this.housenmbrRegex.test(this.postdata.house_number)) {
          this.handleValidationError("house_number");
          return;
        }

        this.getStreets({
          zip: this.postdata.zip,
          house_number: this.postdata.house_number,
          onSuccess: () => {
            // this.loader = false;
            // this.dialog = true;
            this.$emit("nextPage", "post");

            setTimeout(() => {
              // this.first = false;
              // this.second = true;
            }, 2000);
            setTimeout(() => {
              //this.$emit("nextPage", "post");
              // this.dialog = false;
            }, 4000);
          },
          onError: () => {
            this.handleError();
          },
        });
      } else {
        console.error("Invalid form data.");
        this.handleValidationError();
      }
    },
    handleValidationError(field) {
      // this.loader = false;
      this.dialog = false;
      this.first = false;
      this.setAddress = field === "zip";
      this.setHouseNumber = field === "house_number";
      setTimeout(() => {
        this.setAddress = this.setAddress;
        this.setHouseNumber = this.setHouseNumber;
      }, 5000);
    },
    handleError() {
      this.loader = false;
      setTimeout(() => {
        this.setAddress = true;
        this.setHouseNumber = true;
        this.dialog = false;
        this.first = false;
      }, 2000);

      setTimeout(() => {
        this.setAddress = true;
        this.setHouseNumber = true;
      }, 5000);
    },
  },

  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      if (this.postdata.zip === "") {
        return null;
      }
      if (this.getErrorResponses.zip === false) {
        return false;
      } else if (this.zipRegex.test(this.postdata.zip)) {
        return true;
      }
      return false;
    },
    houseNumberValidation() {
      if (
        this.postdata.house_number === "" &&
        !this.getErrorResponses.house_number
      ) {
        return null;
      }
      if (this.getErrorResponses.house_number === false) {
        return false;
      } else if (this.housenmbrRegex.test(this.postdata.house_number)) {
        return true;
      }
      return false;
    },
    isValidForm() {
      return (
        this.postdata.zip &&
        this.postdata.house_number &&
        (!this.setAddress || this.zipValidation) &&
        (!this.setHouseNumber || this.houseNumberValidation)
      );
    },
    // ...mapGetters(["getErrorResponses"]),
    // zipValidation() {
    //   if (this.postdata.zip === "" && !this.getErrorResponses.zip) {
    //     return null;
    //   }
    //   if (this.getErrorResponses.zip == false) {
    //     return false;
    //   } else if (
    //     this.zipRegex.test(this.postdata.zip) ||
    //     this.postdata.zip === ""
    //   ) {
    //     return true;
    //   }
    //   return this.zipRegex.test(this.postdata.zip) || this.postdata.zip === "";
    // },
    // houseNumberValidation() {
    //   if (
    //     this.postdata.house_number === "" &&
    //     !this.getErrorResponses.house_number
    //   ) {
    //     return null;
    //   }
    //   if (this.getErrorResponses.house_number == false) {
    //     return false;
    //   } else if (
    //     this.housenmbrRegex.test(this.postdata.house_number) ||
    //     this.postdata.house_number === ""
    //   ) {
    //     return true;
    //   }
    //   return (
    //     this.housenmbrRegex.test(this.postdata.house_number) ||
    //     this.postdata.house_number === ""
    //   );
    // },

    // isValidForm() {
    //   return (
    //     this.postdata.zip &&
    //     this.postdata.house_number &&
    //     (!this.setAddress || this.zipValidation) &&
    //     (!this.setHouseNumber || this.houseNumberValidation)
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  padding: 1.375rem 1.5rem !important;
  border-radius: 0px !important;
  letter-spacing: 2px !important;
  font-weight: 400;
  @media (max-width: 1024.99px) {
    padding: 1.375rem 0.75rem !important;
  }
}
.form-control::placeholder {
  color: #000000 !important;
}
.text-green {
  color: #5cba01 !important;
}
.modal-text {
  font-size: 45px;
  font-weight: 800;
}
.postcode {
  background: #ffe786;
  padding-top: 90px;
  padding-bottom: 90px;

  &__text {
    font-size: 36px;
    line-height: 40px;
    color: #000000;
    font-weight: 800;
  }

  .form-control {
    background-color: #ffffff;
    border: 1px solid #cccbcb;
    border-radius: 0px !important;
    color: #495057 !important;
    padding: 1.375rem 0.75rem !important;
  }

  .btn {
    border-radius: 37px;
    background-color: #1c1829;
    padding: 13px;
    // font-size: 17px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fefefe;
    font-weight: 900;
    border: none !important;

    &:hover,
    :active {
      background-color: #1d1729;
      color: #fefefe;
    }
  }

  @media (max-width: 1023.99px) {
    &__text {
      font-size: 25px;
      line-height: 30px;
    }
    .btn {
      font-size: 13px;
    }
    img {
      width: 30px;
    }
  }

  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  // .col-md-5{
  //    max-width: 92%;
  //   }
  @media (max-width: 575.99px) {
    &__text {
      font-size: 16px;
      line-height: 25px;
    }
    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    img {
      width: 25px;
    }
  }
  .form_div {
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .postal_code {
    width: 14rem !important;
  }
  .house_number {
    width: 10rem !important;
    @media (max-width: 381.99px) {
      margin-top: 10px;
    }
  }
}
</style>
